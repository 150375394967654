body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.row {
  --bs-gutter-x: 0rem;
}
@font-face {
  font-family: "Endurance Pro";
  src: local("Endurance Pro"),
    url("./assets/fonts/Endurance/EnduranceProRegular.ttf") format("truetype");
}

@font-face {
  font-family: "FS Meridian";
  src: local("FS Meridian"),
    url("./assets/fonts/FSmeridian/FSMeridian-Regular.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.header {
  margin-top: 0px;
  margin-left: 0px;
  width: 100%;
  /* height: 200px; */
  background: #0b1624 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 50px 50px;
}
.headerContent {
  text-align: left;
  font: normal normal bold 30px/57px FS Meridian;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}
.headerContent::after {
  content: "";
  display: block;
  margin-top: 20px;
  left: 0px;
  width: 650px;
  height: 8px;
  position: relative;
  background: transparent linear-gradient(90deg, #00eee6 0%, #a2ffe8 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.sideBar {
  opacity: 1;
  font-weight: bold;
  margin-right: 25px;
  margin-top: 0px;
  border: 1px solid #c6c6c6;
  font: normal normal bold 14px/20px Endurance Pro;
}
.first {
  text-align: left;
  font: normal normal normal 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
}
h6 {
  text-align: left;
  font: normal normal bold 16px/20px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  text-transform: uppercase;
  opacity: 1;
}
p {
  text-align: left;
  font: normal normal normal 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  line-height: 30px;
  margin-bottom: 0rem;
}
.tableFirst {
  border: 1px solid #c6c6c6;
  opacity: 1;
}
.spacialTr {
  vertical-align: middle;
}
.tableFirst tr td {
  border: 1px solid #c6c6c6;
  text-align: left;
  font: normal normal normal 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  opacity: 1;
}
.theadDark {
  background: #0b1624 0% 0% no-repeat padding-box;
}
.tableFirstRow {
  color: white;
  text-align: center;
}
.accordion {
  border: 1px solid #c6c6c6;
}
.accordionTh {
  white-space: nowrap;
}
.accordionRowTable {
  font: normal normal normal 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
}
.accordionRowTable th {
  padding-right: 50px;
}
.accordionList {
  text-align: left;
  font: normal normal normal 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
}
.formPadding {
  padding-right: 5%;
}
.formDisplayInline {
  display: inline-block;
}
.red {
  color: red;
}
.img {
  width: 94px;
  height: 48px;
  margin-bottom: 32px;

}
label {
  text-align: left;
  font: normal normal normal 15px/24px Endurance Pro;
  letter-spacing: 0px;
  opacity: 1;
}
.labelCheck {
  color: var(--unnamed-color-0b1624);
  text-align: left;
  font: normal normal normal 12px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  opacity: 1;
}
::-webkit-input-placeholder {
  font-size: 13px;
}
.dragDropDiv {
  /* UI Properties */
  border: 1px dashed var(--unnamed-color-0b1624);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px dashed #0b1624;
  border-radius: 8px;
  opacity: 1;
  text-align: center;
  padding: 5px;
  margin-top: 30px;
}
.dragDropDivAli {
  vertical-align: top;
}
.paraDrag {
  text-align: center;
  font: normal normal normal 14px/24px Endurance Pro;
}
.buttonForm {
  border: none;
  background-color: transparent;
  font-weight: bold;
}
.paragraphPadding {
  padding-bottom: 1rem;
}
.tickIcon {
  width: 81px;
  height: auto;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 20px;
}
.SuccessPagePara {
  text-align: center;
  font: normal normal normal 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  opacity: 1;
  padding: 0em 8em;
  margin-top: 3%;
}
.goToHomePage {
  color: black;
  font-weight: bold;
  text-align: right;
  top: 1018px;
  font: normal normal bold 16px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  opacity: 1;
  float: right;
  margin-top: 5%;
}
.sideBarButton {
  border: none;
  border-bottom: 1px solid gray;
  background-color: unset;
  text-align: left;
  padding: 3% 0% 3% 5%;
  font-weight: bold;
}
.sideBarStyling {
  color: gray;
  font-weight: bold;
}

input[type="text"],
textarea {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
input[type="email"],
textarea {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
textarea[type="text"],
textarea {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}
.btnText {
  color: var(--unnamed-color-0b1624);
  text-align: center;
  font: normal normal bold 14px/24px Endurance Pro;
  letter-spacing: 0px;
  color: #0b1624;
  opacity: 1;
}
.submitBtn {
  background: #00eee6 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  width: 140px;
  height: 40px;
}
.cancelBtn {
  border-radius: 8px;
  opacity: 1;
  width: 140px;
  height: 40px;
}
.clearBtn {
  border-radius: 8px;
  opacity: 1;
  width: 140px;
  height: 40px;
  float: right;
}
.accordion-body {
  padding: 2rem 1.25rem;
}

.sucess-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerContent {
  text-align: left;
  font: normal normal bold 48px/58px FS Meridian;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

.align-right {
  align-self: end;
}

.align-left {
  align-self: baseline;
}


.spinner-size{
  height: 100px;
  width: 100px;
}

.overlay {
  position: fixed;
  width: 100%; 
  height: 100%; 
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.5;
  z-index: 2; 
  cursor: pointer; 
}
.bigScreenShow{ 
  display:none;
}
.smallDeviceHide{ 
  display: inline-block;
}
@media (min-width: 300px) and (max-width: 500px) {
  .clearBtn{
    float: unset;
  }
  .align-right {
    align-self: unset;
  }

  .align-left {
    align-self: unset;
  }
  .headerContent::after {
    width: unset;
    margin-top: 16px;
  }

  .headerContent {
    font-size: 18px;
    line-height: 24px;
    width: 299px;
  }

  .header {
    padding: 24px;
  }

  .img {
    width: 48px;
    height: 24px;
    margin-bottom: 32px;
  }

  h6 {
    margin: 16px 0;
    font: normal normal bold 12px/20px Endurance Pro !important;
  }

  h2 {
    font: normal normal normal 12px/24px Endurance Pro !important;
  }

  p:first-child {
    padding-top: 16px;
  }

  p {
    font: normal normal normal 12px/24px Endurance Pro !important;
  }

  th,
  td {
    padding: 5px !important;
    vertical-align: middle;
    font: normal normal normal 12px/16px Endurance Pro !important;
  }

  .tableFirstRow {
    font: normal normal bold 12px/24px Endurance Pro;
  }

  .accordion-button,
  .accordion-body {
    padding: 10px;
  }

  .sideBarButton {
    font: normal normal bold 12px/20px Endurance Pro !important;
  }

  .formDisplayInline {
    display: block;
  }

  .form-group {
    margin: 16px 0;
  }
  .clearBtn {
    margin-top: 16px;
  }
  .SuccessPagePara {
    padding: 0 55px;
  }

  .goToHomePage {
    margin-top: 86px;
  }
  .spinner-size{
    height: 45px;
    width: 45px;
  }
  .bigScreenShow{ 
    display:block;
  }
  .smallDeviceHide{ 
    display:none !important;
  }
}

@media (min-width: 501px) and (max-width: 730px) {

  .clearBtn{
    float: unset;
  }
  .SuccessPagePara {
    padding: 20px;
  }

  th,
  td {
    padding: 5px !important;
    vertical-align: middle;
    font: normal normal normal 12px/16px Endurance Pro !important;
  }

  .tableFirstRow {
    font: normal normal bold 12px/24px Endurance Pro;
  }

  h6 {
    margin: 16px 0;
  }
  .headerContent::after {
    content: "";
    display: block;
    margin-top: 20px;
    left: 0px;
    width: 420px;
  }
}
